<script context="module" lang="ts">
	declare const umami: any;

	export const isProduction = import.meta.env.VITE_APP_ENV === 'production';

	if (isProduction) {
		const umamiScript = document.createElement('script');
		umamiScript.async = true;
		umamiScript.defer = true;
		umamiScript.setAttribute('data-website-id', '98e2b3b2-20e3-431e-bd10-7d973a43c481');
		umamiScript.src = 'https://analytics.eu.umami.is/script.js';
		document.head.appendChild(umamiScript);
	}

	export function recordEvent(event: string) {
		if (isProduction) {
			umami.track(event);
		}
	}
</script>
